import React from 'react';
import { Carousel } from 'react-bootstrap';
import { Container, Row, Col, Card } from 'react-bootstrap';
import logo1 from '../Images/client/ayg.png';
import logo2 from '../Images/client/megasails.jpg';


const clients = [
  { name: 'Client 1', logo: logo1 },
 
  { name: 'Client 2', logo: logo2 },
  // Add more clients as needed
];

const ClientSection = () => {
  return (
    <Container className="my-5" >
    <div className=" pt-2">
      <Row>
      <Col>
        <h2>We are trusted by renowned brands</h2>
        <p>Driving their business growth in social media landscape.</p>
      </Col>
    </Row>
        </div>
    <div className="client-slider-container">      
      <Carousel indicators={false} controls={false} interval={100} className="client-slider">
        {clients.map((client, index) => (
          <Carousel.Item key={index}>
            <div className="d-flex justify-content-around align-items-center">
              {clients.slice(index, index + 7).map((clientSlice, sliceIndex) => (
                <div key={sliceIndex} className="client-logo">
                  <img
                    src={clientSlice.logo}
                    alt={clientSlice.name}
                    className="d-block"
                    style={{ maxHeight: '200px', objectFit: '' }}
                  />
                </div>
              ))}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
    </Container>
  );
};

export default ClientSection;
