import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './HeroHomeSection.css';

function HeroHomeSection() {
  return (
    <div className="hero-section">
      <Container>
        <Row className="text-center text-white">
          <Col>
            <h1>Your Reliable Technology Experts</h1>
            <p className="lead"></p>
            
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HeroHomeSection;
