import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className="hero-section">
      <Container>
        <Row className="text-center text-white">
          <Col>
            <h1 className="display-4">Cloud Solutions</h1>
            <p className="lead">Reliable Cloud Solutions with Azure and AWS: Backup, Migration & Hosting
</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HeroSection;
