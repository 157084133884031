import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './HeroSection.css';

function HeroWebDesignSection() {
  return (
    <div className="hero-section">
      <Container>
        <Row className="text-center text-white">
          <Col>
            <h1 className="display-4">Web Design</h1>
            <p className="lead">Create stunning, responsive, and mobile-friendly websites that adhere to the highest standards in professional design, development, and secure managed hosting.






</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HeroWebDesignSection;
