import React from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import Navbar from "../Componets/navbar";


const services = [
  { title: "Web Development", description: "Custom websites tailored to your business needs." },
  { title: "SEO Optimization", description: "Boost your website visibility with our SEO services." },
  { title: "E-commerce Solutions", description: "Start selling online with our e-commerce integrations." },
  { title: "UX/UI Design", description: "Beautiful, user-friendly designs that engage visitors." },
];


function Services() {

  return (
    <div>
    <Navbar />
    <Container id="services" className="my-5">
      <h2 className="text-center">Our Services</h2>
      <Row className="mt-4">
        {services.map((service, index) => (
          <Col key={index} md={6} lg={3} className="mb-4">
            <Card className="h-100 text-center">
              <Card.Body>
                <Card.Title>{service.title}</Card.Title>
                <Card.Text>{service.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
    </div>
  );
}

export default Services;
