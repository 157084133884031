import React from "react";
import { Helmet } from 'react-helmet';
import Navbar from "../Componets/navbar";
import HeroWebDesignSection from '../Componets/HeroWebDesignSection';
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import  trends1 from "../Images/trends_001.jpg"
import  web from "../Images/web.jpg"
import  app from "../Images/app.jpg"
import oklah from "../Images/oklah_office.jpg";
import Local_SEO from "../Images/oklah-Local_SEO.jpg";
import analytics from "../Images/analytics_reporting.jpg";
import content from "../Images/content-marketing.jpg";
import socialmedia from "../Images/social-media-marketing.jpg";
import payper from "../Images/pay-per-click.jpg";
import ayg from "../Images/ayg.png";
import megasails from "../Images/megasails.png";

const WebDesign = () => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (    
    <div style={{ backgroundColor: "#f4f4f4" }}>
      <Helmet>
      <title></title>
    <meta name="description" content="Discover a wide range of digital marketing services from Oklah. We offer SEO, PPC, social media, and more to boost your online presence in Singapore." />
    <meta name="keywords" content="digital marketing, online marketing, SEO, social media, PPC, branding" />
  </Helmet>
          <Navbar />
          <HeroWebDesignSection />
          
                <div>
        
      </div>
      <div>
        <Container fluid>
          <Row className="g-3 py-3">
          <Col xs={12} md={6} lg={6}>
              <Card className="rounded-0 border-0 p-lg-4">
                <Card.Body>
                  <Card.Title className="text-center  fw-bold ">
                  American Yacht Group
                  </Card.Title>
                  <Card.Text className="text-center fs-18px">
                  As a leading yacht brokerage, we specialize in smooth experiences for buying and selling boats, motor yachts, cruising vessels, and center-consoles
                  </Card.Text>
                  
                </Card.Body>
                <Link
                      to={`https://www.americanyachtgroup.com/`}
                      className=" text-dark fw-bold"
                      onClick={handleClick}
                    >
                <Card.Img
                  variant="bottom"
                  src={ayg} style={{ height: "350px" }}
                />
                <Card.Text className="text-center fs-18px">
                  
                Visit Website{"  "}
                      <i class="fa-solid fa-angles-right"></i>
                    
                  </Card.Text>
                  </Link>
              </Card>
              
            </Col>

            <Col xs={12} md={6} lg={6}>
              <Card className="rounded-0 border-0 p-lg-4">
                <Card.Body>
                  <Card.Title className="text-center fw-bold ">
                  Megasails
                  </Card.Title>
                  <Card.Text className="text-center fs-18px">
                  MegaSails specializes in new and used yacht brokerage. We list center consoles, superyachts, motor yachts, catamarans, sailboats, and more using MLS.
                  </Card.Text>
                  
                </Card.Body>
                <Card.Img
                  variant="bottom"
                  src={megasails} style={{ height: "350px" }}
                />
                <Card.Text className="text-center fs-18px">
                  <Link
                      to={`https://www.megasails.com/`}
                      className=" text-dark fw-bold"
                      onClick={handleClick}
                    >
                     Visit Website{"  "}
                      <i class="fa-solid fa-angles-right"></i>
                    </Link>
                  </Card.Text>
              </Card>
              
            </Col>
            
            
            
          </Row>
        </Container>
      </div>

      
      </div>
  );
};

export default WebDesign;
