import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Carousel, Col, Container, Row,Card,Button } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import "../App.css";
import heroimageone from "../Images/hero_001.jpg"
import  heroimagetwo from "../Images/hero_002.jpg"
import  heroimagethree from "../Images/hero_003.jpg"
import  web from "../Images/web.jpg"
import  app from "../Images/app.jpg"
import  hero from "../Images/hero_004.jpg"
import  trends1 from "../Images/trends_001.jpg"
import  trends2 from "../Images/trends_002.jpg"
import  trends3 from "../Images/trends_003.jpg"
import  dhanam from "../Images/dhanam.jpg"
import ClientSection from './ClientSection';
import  oklah from "../Images/oklah_office.jpg"
import Navbar from '../Componets/navbar';
import HeroHomeSection from '../Componets/HeroHomeSection';
import OverviewSection from '../Componets/OverviewSection';
import  ayg from "../Images/ayg.png";
import  dhanamoffice from "../Images/dhanamoffice.webp";
function Home() {
  return (
    
    <>
 
      <div>
      <Helmet>
      <title>Dhanam Inforamtion Systems</title>
        <meta name="description" content="Oklah is a leading & affordable digital marketing agency in Singapore. Our services include SEO, PPC, social media, and more. Contact us today!" />
        <meta name="keywords" content="digital marketing, online marketing, SEO, social media, PPC, branding" />
      </Helmet>
<Navbar/>

<HeroHomeSection/>
<OverviewSection/>  
      
      <Container id="services" className="my-5 ">
      <Row>
        <Col>
          <h2>What we do</h2>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Card className="my-3">
            <Card.Body>
              <Card.Title>Consulting & Solution Architecture Services</Card.Title>
              <Card.Text>
              Our IT consulting & solution architecture services bring deep, functional expertise to focus on our clients' most critical technology, transformation, digital, advanced analytics, mergers & acquisitions, and sustainability to meet organization objectives and embrace new technology.




              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="my-3">
            <Card.Body>
              <Card.Title>Software development
</Card.Title>
              <Card.Text>

              Dhanam Information Systems helps to build and maintain custom software products through an extended research, architecture, and development team for our customers. The methods established by Dhanam for custom software product development have transformed significantly to improve customer IT portfolio and operations







              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="my-3">
            <Card.Body>
              <Card.Title>Cloud Migration Services
</Card.Title>
              <Card.Text>
              Dhanam’s Cloud migration services are aimed at moving companies’ applications and databases to the cloud environment to achieve greater flexibility and cost-effectiveness. Dhanam’s process cover migration planning, design, execution, and support of the migrated solutions





              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="my-3">
            <Card.Body>
              <Card.Title>IT Staffing Services</Card.Title>
              <Card.Text>
              Dhanam’s IT staffing services can close your company’s IT talent gap by providing high quality on-demand with top-notch abilities across the wide spectrum of information technology areas including application development, Software testing, Project management, Infra and network management



              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="my-3">
            <Card.Body>
              <Card.Title>Mobile Application Development
</Card.Title>
              <Card.Text>
              The increasing number of smartphone users has led to a tremendous increase in the number of apps that consumers use on their phones. Dhanam’s custom mobile development enables its prospective customers to take a big leap towards their digital journey.




              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="my-3">
            <Card.Body>
              <Card.Title>Application Maintenance Services (AMS)
</Card.Title>
              <Card.Text>
              Dhanam’s AMS processes bring in business relevant application maintenance service models such as dedicated team, shared support, shared services, staff augmentation supported by onsite, near shore and offshore teams located in US, Mexico, and India.



</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="my-3">
            <Card.Body>
              <Card.Title>PMO Services

</Card.Title>
              <Card.Text>
              Our PMO services are designed to work with our small and medium size companies to foster acceptance and make the adoption of a standard Methodology welcomed within the clients PMO office culture. Our PM solutions brings you the expertise to address common areas of concern including scope, resource availability and project controls to complete projects as planned.




</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="my-3">
            <Card.Body>
              <Card.Title>SAP Services


</Card.Title>
              <Card.Text>
              Our SAP AMS services are personalized and designed to support Small to Medium size companies. Our innovative approach enables us to provide an array of support services, including break fix, testing, ongoing application patching, end-user resolution, enhancements etc. in relation to SAP Functional Modules, Technical development (ABAP, PI, XI), Security etc., and additional third-party interfaces & applications.





</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="my-3">
            <Card.Body>
              <Card.Title>ITO services



</Card.Title>
              <Card.Text>
              Our services are designed to provide a clear path to IT modernization for small and medium size companies, whether it is in your data center or in any cloud including AWS, Microsoft Azure and Google Cloud to ensure disruption-free IT services today and in the future, and make your business transformation successful






</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>    
            
          <div className="text-center  py-2">
          <Link to={`/it-services`} variant=""> <button className="btn  text-center" style={{ marginRight: "10px" }}></button><Button
                  className="lh-base mt-3"
                  variant="outline-dark"
                  
                >
                  See Our Services 
                </Button></Link>
            
          </div>

          
    </Container>
      
      <section>
        <Container>
        <div className=" pt-2">
        <Row>
        <Col>
          <h2>We keep close to the latest digital marketing trends</h2>
          <p>To offer insights that enhance your marketing campaigns.</p>
        </Col>
      </Row>
          </div>
          <Row className="d-flex justify-content-center py-4">
            <Col md={4}>
            <Card.Img
                  variant="top"
                  src={trends1}
                  className="bg-dark rounded-0"
                  height={"250px"}
                />
              <Card className="">
            <Card.Body>
              <Card.Title>Using Social Media Channels To Increase Restaurant Exposure
</Card.Title>
              
            </Card.Body>
          </Card>
            </Col>
            <Col md={4}>
            <Card.Img
                  variant="top"
                  src={trends2}
                  className="bg-dark rounded-0"
                  height={"250px"}
                />
              <Card className="">
            <Card.Body>
              <Card.Title>Is Pay Per Click Strategy useful for digital marketing?

</Card.Title>
              
            </Card.Body>
          </Card>
            </Col>
            <Col md={4}>
            <Card.Img
                  variant="top"
                  src={trends3}
                  className="bg-dark rounded-0"
                  height={"250px"}
                />
              <Card className="">
            <Card.Body>
              <Card.Title>How to create a successful B2B SEO strategy?

</Card.Title>
              
            </Card.Body>
          </Card>
            </Col>
          </Row>
          <div className="text-center pt-2">        
            

            <Link to={`/insights`} variant=""> <button className="btn  text-center" style={{ marginRight: "10px" }}></button><Button
                  className="lh-base mt-3"
                  variant="outline-dark"
                  
                >
                   Read More 
                </Button></Link>
          </div>
        </Container>
      </section> 
    
      <section> 
      <Container className="py-5 text-center">
      <div className=" pt-2">
      <img               
                src={ayg} alt='OKLAH'
                
              />
              </div>
              <div className="text-center pt-4 py-3">        
            

            <Link to={`/web-design`} variant=""> <button className="btn  text-center" style={{ marginRight: "10px" }}></button><Button
                  className="lh-base mt-3" variant="outline-dark"                
                >
                  See All Our Projects  
                </Button></Link>
          </div>
              </Container>
              </section>
            
      <ClientSection />
      
      <Container className="py-2">
      <h2>with the bustling city centres just a stone’s throw away.</h2>
          <p></p>

          </Container>
      <section>
      
        
        <div>
        
         
        
          <img
src={dhanamoffice}
            
           
            className="img-fluid"
          />
          <div
           
          >
            
            
          </div>
        </div>
        

          <div className="text-center  py-4">
          
            <Link to={`/about`} variant=""> <button className="btn  text-center" style={{ marginRight: "10px" }}></button><Button
                  className="lh-base mt-3" variant="outline-dark"                
                >
                  Meet the Team
                </Button></Link>
          </div>
      </section>


      </div>
      
      
      
      
      
      
    </>
  );
}

export default Home;
