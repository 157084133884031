import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './HeroSection.css';

function HeroAboutSection() {
  return (
    <div className="hero-section">
      <Container>
        <Row className="text-center text-white">
          <Col>
            <h1 className="display-4">Information Technology is what we do</h1>
            <p className="lead">We bring innovation, experience, knowledge and thought leadership to Information Technology Solution Architecture and Engineering, for delivering customers specific business needs.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HeroAboutSection;
