
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Contact from "./Pages/contact";  
import Socialmedia from "./Pages/social-media-trends";  
import About from "./Pages/about";   
import Services from "./Pages/services"; 
import WebDev from "./Pages/website-development"; 
import AppsDev from "./Pages/apps-development";    
import Insights1 from "./Pages/using-social-media-channels-to-increase-restaurant-exposure"; 
import Insights2 from "./Pages/how-to-create-a-successful-btwob-seo-strategy";
import Insights3 from "./Pages/is-pay-per-click-strategy-useful-for-digital-marketing"; 
import Payperclick from "./Pages/pay-per-click-advertising-driving-traffic-to-your-website"; 
import BusinessApplications from "./Pages/business-applications"; 
import SerachEngineMarketing from "./Pages/search-engine-marketing";       
import ContentMarketing from "./Pages/content-marketing";
import LocalSEO from "./Pages/local-seo"; 
import AnalyticsReporting from "./Pages/analytics-and-reporting";  
import PrivacyPolicy from "./Pages/privacy-policy";
import Insights from "./Pages/insights";
import ITServices from "./Pages/it-services";
import WebDesign from "./Pages/web-design";
import Cloud from "./Pages/cloud";           
import Footer from "./Componets/footer";
import { useEffect } from "react";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/social-media-trends" element={<Socialmedia />} />
          <Route path="/about" element={<About />} />
          <Route path="/website-development" element={<WebDev />} />
          <Route path="/apps-development" element={<AppsDev />} />
          <Route path="/using-social-media-channels-to-increase-restaurant-exposure" element={<Insights1 />} />
          <Route path="/how-to-create-a-successful-btwob-seo-strategy" element={<Insights2 />} />
          <Route path="/is-pay-per-click-strategy-useful-for-digital-marketing" element={<Insights3 />} />
          <Route path="/pay-per-click-advertising-driving-traffic-to-your-website" element={<Payperclick />} />
          <Route path="/business-applications" element={<BusinessApplications />} />
          <Route path="/search-engine-marketing" element={<SerachEngineMarketing  />} />
          <Route path="/content-marketing" element={<ContentMarketing  />} />
          <Route path="/local-seo" element={<LocalSEO  />} />
          <Route path="/analytics-and-reporting" element={<AnalyticsReporting  />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy  />} />
          <Route path="/insights" element={<Insights  />} />
          <Route path="/cloud" element={<Cloud  />} />
          <Route path="/it-services" element={<ITServices  />} />
          <Route path="/web-design" element={<WebDesign  />} />
        </Routes>       
       <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;