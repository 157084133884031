import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './HeroSection.css';

function HeroContactSection() {
  return (
    <div className="hero-section">
      <Container>
        <Row className="text-center text-white">
          <Col>
            <h1 className="display-4">Speak with us</h1>
            <p className="lead">At Dhanaminfosys, simply fill out the contact form below with your details, and one of our experts will reach out to schedule a convenient time for a conversation.

</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HeroContactSection;
