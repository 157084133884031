import React from "react";
import { Container, Jumbotron } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import about from "../Images/about.webp";
import Navbar from "../Componets/navbar";
import HeroAboutSeaction from "../Componets/HeroAboutSeaction";
import web from "../Images/web.jpg";
import Team from '../Componets/team';
const About = () => {
  return (
    <div>
      <Helmet>
        <title>  </title>
        <meta name="description" content="Oklah is a leading & affordable digital marketing agency in Singapore. Our services include SEO, PPC, social media, and more. Contact us today!" />
        <meta name="keywords" content="digital marketing, online marketing, SEO, social media, PPC, branding" />
      </Helmet>
      <Navbar />
      <HeroAboutSeaction />
      <section>
     
    </section>
      <section className="news-details my-2">     
        <div className="container p-5 ">
          <div className="row mb-2">
            <div className="col-12 col-md-6 d-flex align-items-center text-primary-emphasis">
              <div>                
                <h1 className="fw-bold">
                Who we are

                </h1>
                
              </div>
            </div>
            
          </div>
          <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
          Dhanam resources has 25+ years of IT industry experience in Software development and managing small to large IT Portfolio.

          </p>
          <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
          Dhanam’s core value is to it help companies to accelerate change and adapt to changing tech stacks and Platforms to meet digital requirements


          </p>          
         
          <div className="row mb-5">
            <div className="col-12 col-md-6">
              <img
              src={about}
              
                alt="..."
                className="img-fluid mt-3"
              />
            </div>
            <div className="col-12 col-md-6">
            <p
            style={{ textAlign: "justify" }}
            className="text-primary-emphasis fw-bold"
          >
            We Ask the Right Questions About Your Business


          </p>
              <p
                style={{ textAlign: "justify" }}
                className="text-primary-emphasis"
              >
                Before we start any project, we take the time to get to know your business. We ask the right questions to gain a deep understanding of your business, target audience, and competition.


              </p>
              <p
            style={{ textAlign: "justify" }}
            className="text-primary-emphasis fw-bold"
          >
           Developing Customized Solutions



          </p>
              <p
                style={{ textAlign: "justify" }}
                className="text-primary-emphasis"
              >
                Once we have a clear understanding of your business, we work alongside you to develop customized solutions that meet your specific needs. We deliver a solution that meets your expectations.


              </p>
              <p
            style={{ textAlign: "justify" }}
            className="text-primary-emphasis fw-bold"
          >
           Launching Your Solutions




          </p>
          <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
          We don’t just develop your solutions and leave you to it. We work alongside you to launch your solutions and provide ongoing support and maintenance to ensure that they continue to meet your needs over time.


          </p>
            </div>
          </div>
          <p
            style={{ textAlign: "justify" }}
            className="text-primary-emphasis fw-bold"
          ></p>
             <Team />
        </div>
        
      </section>
 
    </div>
  );
};

export default About;
