import React from 'react';
import { Container, Row, Col,Nav ,Form ,Button} from 'react-bootstrap';
import './footer.css';
import { FaFacebook, FaTwitter, FaLinkedin,FaInstagram,FaYoutube } from 'react-icons/fa'; // Import the social media icons
const Footer = () => {
  return (
    <footer className="footer-custom">
      <Container>
        <Row>
          <Col md={3}>
          <h5>Sign up for our newsletter</h5>
            
            <Form.Group controlId="lastName">
              <Form.Control
                className="mb-2"
                type="text"
                name="NM_lastName"
                placeholder="email address"
               
               
                required
              />
            </Form.Group>
            <Button
                  className="lh-base mt-3"
                  variant="outline-dark"
                  
                >
                  Submit 
                </Button>
          </Col>
          <Col md={3}>
            <h5>Navigation</h5>
            <ul className="list-unstyled">             
              <li><a href="/about">About</a></li>              
              <li><a href="/contact">Contact</a></li>
              <li><a href="">Privacy Policy</a></li>
            </ul>
          </Col>
          <Col md={3}>
            <h5>Say Hello</h5>
            <ul className="list-unstyled">
              <li><i className="fas fa-map-marker-alt"></i> Dhanam Information Systems</li>             
              <li><i className="fas fa-envelope"></i> info@dhanaminfosys.com</li>
             
            </ul>
          </Col>
          <Col md={3}>
            <h5>Follow Us</h5>
            <ul className="list-unstyled social-links">
              <li><a href="" target="_blank"><i className="fab fa-facebook-f"></i><FaFacebook size={24} /></a></li>              
              <li><a href=""><i className="fab fa-linkedin-in" target="_blank"></i><FaInstagram size={24} /></a></li>
              <li><a href="#" target="_blank"><i className="fab fa-instagram"></i><FaLinkedin size={24} /></a></li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <p>&copy; {new Date().getFullYear()} Dhanam Information Systems</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
