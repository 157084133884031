import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function OverviewSection() {
  return (
    <Container className="my-5">
      <div className="row mb-5">
              <div className="col-12 col-md-6">

            
            <h4
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
          Dhanam resources has 25+ years of IT industry experience in Software development and managing small to large IT Portfolio.



            </h4>
            
              </div>
              <div className="col-12 col-md-6">
              
              
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">    
            Dhanam Resources takes pride in understanding each client’s unique challenges and goals. We offer tailored strategies to streamline operations, reduce costs, and enhance efficiency, all while maintaining a focus on security and scalability. Our commitment to quality and continuous improvement ensures that our clients are equipped with the tools and insights necessary to thrive in an ever-evolving digital landscape.

</p>




            
            
             
              </div>
            </div>
    </Container>
  );
}

export default OverviewSection;
