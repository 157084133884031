import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './HeroSection.css';

function HeroInsightsSection() {
  return (
    <div className="hero-section">
      <Container>
        <Row className="text-center text-white">
          <Col>
            <h1 className="display-4">Insights</h1>
            <p className="lead">Access detailed guides, expert Q&As, and the latest updates in technology and IT—all brought to you by our knowledgeable team.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HeroInsightsSection;
