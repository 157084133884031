import React from "react";
import { Helmet } from 'react-helmet';
import oklah from "../Images/oklah_office.jpg";
import servicenow from "../Images/servicenow.png";
import microsoft from "../Images/microsoft.webp";
import Navbar from "../Componets/navbar";
import HeroBusinessSection from "../Componets/HeroBusinessSection";
import oklahapps from "../Images/oklahapps.jpg";
const BusinessApplications = () => {
    return (
      <div>
        <Helmet>
        <title>Business Applications </title>
    <meta name="description" content="Discover a wide range of digital marketing services from Oklah. We offer SEO, PPC, social media, and more to boost your online presence in Singapore." />
    <meta name="keywords" content="digital marketing, online marketing, SEO, social media, PPC, branding" />
  </Helmet>
        <Navbar />
        <HeroBusinessSection/>
                
        <section className="news-details my-1">
        
          <div className="container p-1 text-primary-emphasis">
          
            
            <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
            SAP
            </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">

            Our SAP AMS services are personalized and designed to support Small to Medium size companies. Our innovative approach enables us to provide an array of support services, including break fix, testing, ongoing application patching, end-user resolution, enhancements etc. in relation to SAP Functional Modules, Technical development (ABAP, PI, XI), Security etc., and additional third-party interfaces & applications.            </p>
            
     
            
            <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
          Learn more about our SAP Solutions:</p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
            ●	SAP Business One
            </p>            
            
            <div className="row mb-5">
              <div className="col-12 col-md-6">

            
            <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
          ServiceNow’s 
            </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">    
            ServiceNow’s CMDB (Configuration Management Database) and ITSM (IT Service Management) work together to optimize IT service delivery and asset management. The CMDB serves as a centralized repository, storing essential information about IT assets and Configuration Items (CIs), enabling detailed tracking and management throughout their lifecycle. This comprehensive asset visibility supports the ITSM suite, which provides robust tools and processes for managing IT services, including incidents, changes, and requests. By integrating CMDB and ITSM, ServiceNow helps organizations ensure efficient, streamlined, and proactive IT service delivery, ultimately enhancing service quality and minimizing operational disruptions.

              </p>     
              </div>
              <div className="col-12 col-md-6">
              
              <img
                   src={microsoft}
                  alt="..."
                  className="img-fluid mt-3"
                />
              </div>
            </div>


            <div className="row mb-5">
              <div className="col-12 col-md-6">

              <img
                   src={servicenow}
                  alt="..."
                  className="img-fluid mt-3"
                />
              </div>
              <div className="col-12 col-md-6">
                     
              <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
Microsoft         
</p>
            <p style={{ textAlign: "justify" }}
                  className="text-primary-emphasis">
                 At Dhanaminfosys, we embrace Microsoft’s commitment to empowering collaboration. We’re proud to offer a comprehensive suite of Microsoft tools designed to elevate your team's productivity and connectivity. Our expert team will guide you in selecting the ideal Microsoft solutions for your business, all backed by the latest advancements in Microsoft’s business software.


  </p>             
                <p style={{ textAlign: "justify" }}
                  className="text-primary-emphasis">
With over 15 years of partnership and full Microsoft accreditation, we have the knowledge and experience to keep your business at the forefront of Microsoft technology. From seamless integration to tailored support, we ensure your organization stays updated and optimized across all Microsoft platforms. Let us help you unlock the full potential of collaborative work with Microsoft solutions.</p>
 
           
            <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
Learn more about Microsoft solutions:

            </p>
            <p style={{ textAlign: "justify" }}
                  className="text-primary-emphasis">
Microsoft 365
</p>
<p style={{ textAlign: "justify" }}
                  className="text-primary-emphasis">
Microsoft Teams & Teams Phone
</p>
                
              </div>
              
            
            </div>     
            
            
            
          </div>
        </section>
      </div>
    );
  };
  
  export default BusinessApplications;
  