import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Carousel, Col, Container, Row,Card,Button } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import dhanam from "../Images/hero.jpg";
import servicenow from "../Images/servicenow.png";
import microsoft from "../Images/microsoft.webp";
import Navbar from "../Componets/navbar";
import HeroSection from "../Componets/HeroSection";
import oklahapps from "../Images/oklahapps.jpg";
import cloud from "../Images/cloud.webp";
const Cloud = () => {
    return (
      <div>
        <Helmet>
        <title>Dhanaminfosys | Cloud</title>
    <meta name="description" content="Discover a wide range of digital marketing services from Oklah. We offer SEO, PPC, social media, and more to boost your online presence in Singapore." />
    <meta name="keywords" content="digital marketing, online marketing, SEO, social media, PPC, branding" />
  </Helmet>
        <Navbar />
        
        <HeroSection />
       

        <section className="news-details my-1">
        
          <div className="container p-1 text-primary-emphasis">
         
            
            <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
            Cloud migrations

            </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
            Businesses of all sizes are harnessing cloud computing to achieve unprecedented scalability and agility. Transitioning to the cloud can be seamless, offering a future-ready solution without the hassle. With cloud technology, your business can scale effortlessly and adapt to changing needs with ease

            </p>
            
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">

            If outdated IT infrastructure is slowing you down, it’s time to consider the cloud. Migrating your systems and data to the cloud brings flexibility, allowing your business to scale effortlessly and adapt to changing needs—saving you time and reducing stress
                </p>
            
                <div className="row mb-5">
              <div className="col-12 col-md-6">

              <img
                   src={cloud}
                  alt="..."
                  className="img-fluid mt-3"
                />
              </div>
              <div className="col-12 col-md-6">
                     
              <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
Microsoft Azure Portal        
</p>
            <p style={{ textAlign: "justify" }}
                  className="text-primary-emphasis">
Microsoft Azure Portal is a powerful, web-based platform that allows users to manage and configure their Azure resources in a user-friendly, customizable interface. It provides a centralized location to monitor, provision, and control applications and services across multiple cloud environments. With its intuitive dashboard, users can access real-time data, analytics, and alerts, making it easier to manage complex cloud deployments. Whether you're a developer, IT professional, or business user, Azure Portal simplifies resource management with easy-to-navigate features.
  </p>             
                <p style={{ textAlign: "justify" }}
                  className="text-primary-emphasis">
The Azure Portal also enables seamless collaboration and integration with other Microsoft tools, such as Microsoft 365, Power BI, and Visual Studio. Its role-based access controls ensure secure and precise management of user permissions, allowing teams to collaborate effectively while maintaining security standards. The portal’s flexibility and scalability make it an essential tool for businesses looking to harness the full potential of cloud computing, offering everything from storage management to advanced machine learning models—all from a single, unified platform.    
            </p>
            <Link to={``} variant=""> <button className="btn  text-center" style={{ marginRight: "10px" }}></button><Button
                  className="lh-base mt-3"
                  variant="outline-dark"
                  
                >
                   Find out more 
                </Button></Link> 
              </div>
              
            
            </div>              
            
            <div className="row mb-5">
              <div className="col-12 col-md-6">

            
            <p
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
         Amazon Web Services

            </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">    
            Amazon Web Services (AWS) offers a comprehensive suite of cloud computing solutions that enable businesses to scale and innovate with ease. With AWS, organizations gain access to powerful infrastructure, computing power, storage, and a wide range of tools designed to meet the needs of startups, enterprises, and everything in between. From machine learning and artificial intelligence to data analytics and networking, AWS provides an integrated, secure, and highly reliable cloud platform that businesses can trust.              </p>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
            With AWS, companies can significantly reduce IT overhead, enhance security, and achieve faster time-to-market for products and services. The flexibility of AWS allows businesses to only pay for what they use, making it a cost-effective solution for any size organization. Whether you're looking to migrate to the cloud, run data-intensive applications, or build scalable web applications, AWS offers the tools and resources to help you succeed in today’s digital landscape.


                </p>
                <Link to={``} variant=""> <button className="btn  text-center" style={{ marginRight: "10px" }}></button><Button
                  className="lh-base mt-3"
                  variant="outline-dark"
                  
                >
                   Find out more 
                </Button></Link> 
              </div>
              <div className="col-12 col-md-6">
              
              <img
                   src={microsoft}
                  alt="..."
                  className="img-fluid mt-3"
                />
              </div>
            </div>


              
            
            
            
          </div>
        </section>
      </div>
    );
  };
  
  export default Cloud;
  