

import React from "react";
import { Helmet } from 'react-helmet';
import oklah from "../Images/oklah_office.jpg";
import servicenow from "../Images/servicenow.png";
import microsoft from "../Images/microsoft.webp";
import Navbar from "../Componets/navbar";
import HeroITSection from '../Componets/HeroITSection';
import oklahapps from "../Images/oklahapps.jpg";
const ITServices = () => {
    return (
      <div>
        <Helmet>
        <title>ITServices </title>
    <meta name="description" content="Discover a wide range of digital marketing services from Oklah. We offer SEO, PPC, social media, and more to boost your online presence in Singapore." />
    <meta name="keywords" content="digital marketing, online marketing, SEO, social media, PPC, branding" />
  </Helmet>
        <Navbar />
        <HeroITSection/>
                
        <section className="news-details my-1">
        
          <div className="container p-1 text-primary-emphasis">      
            
           
            
            <div className="row mb-5">
              <div className="col-12 col-md-6">

            
            <h4
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
          Consulting & Solution Architecture Services

            </h4>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">    
            Our IT consulting & solution architecture services bring deep, functional expertise to focus on our clients' most critical technology, transformation, digital, advanced analytics, mergers & acquisitions, and sustainability to meet organization objectives and embrace new technology.              </p> 

            <h4
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
          Application Maintenance Services (AMS)


            </h4> 
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
            Dhanam’s AMS processes bring in business relevant application maintenance service models such as dedicated team, shared support, shared services, staff augmentation supported by onsite, near shore and offshore teams located in US, Mexico, and India.


                </p>   
              </div>
              <div className="col-12 col-md-6">
              
              <h4
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
          Consulting & Solution Architecture Services

            </h4>
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">    
            Our IT consulting & solution architecture services bring deep, functional expertise to focus on our clients' most critical technology, transformation, digital, advanced analytics, mergers & acquisitions, and sustainability to meet organization objectives and embrace new technology.              </p> 
            <h4
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
          PMO Services
            </h4>  
            <p style={{ textAlign: "justify" }} className="text-primary-emphasis">
            Our PMO services are designed to work with our small and medium size companies to foster acceptance and make the adoption of a standard Methodology welcomed within the clients PMO office culture. Our PM solutions brings you the expertise to address common areas of concern including scope, resource availability and project controls to complete projects as planned.

 
            </p>  
              </div>
            </div>


            <div className="row mb-5">
              <div className="col-12 col-md-6">

              <img
                   src={servicenow}
                  alt="..."
                  className="img-fluid mt-3"
                />
              </div>
              <div className="col-12 col-md-6">
                     
              <h4
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
IT Staffing Services
        
</h4>
            <p style={{ textAlign: "justify" }}
                  className="text-primary-emphasis">
Dhanam’s IT staffing services can close your company’s IT talent gap by providing high quality on-demand with top-notch abilities across the wide spectrum of information technology areas including application development, Software testing, Project management, Infra and network management.

  </p>             
  <h4
              style={{ textAlign: "justify" }}
              className="text-primary-emphasis fw-bold"
            >
ITO services

        
</h4>     
<p style={{ textAlign: "justify" }}
                  className="text-primary-emphasis">
                    Our services are designed to provide a clear path to IT modernization for small and medium size companies, whether it is in your data center or in any cloud including AWS, Microsoft Azure and Google Cloud to ensure disruption-free IT services today and in the future, and make your business transformation successful


                    </p>  
                
              </div>
              
            
            </div>     
            
            
            
          </div>
        </section>
      </div>
    );
  };
  
  export default ITServices;
  