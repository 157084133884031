import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './HeroSection.css';

function HeroBusinessSection() {
  return (
    <div className="hero-section">
      <Container>
        <Row className="text-center text-white">
          <Col>
            <h1 className="display-4">Business Applications</h1>
            <p className="lead">Streamlining Collaboration and Efficiency for SMEs: ServiceNow, SAP, and Microsoft Solutions

</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HeroBusinessSection;
